<template>
  <div class="d-flex mb-48">
    <img :src="sourceAvatar(profiles[0])" @load="avatarLoadHandler" @error="avatarErrorHandler" class="tagged-profile-card__avatar mb-16 mr-32" :class="loadImage ? 'd-block' : 'd-none'">
    <img src="@/assets/icons/profile-card-avatar.svg" alt="" class="tagged-profile-card__avatar mb-16 mr-32" :class="loadImage ? 'd-none' : 'd-block'">
    <div>
      <h3 class="typography-1">{{mainName}}</h3>
      <div class="color-light mb-24">{{mainInfo.location?.value}}</div>
      <template v-if="$route.name === 'Report' && (profiles[0].note || multiprofile?.note)">
        <div class="color-miniheader typography-theme mb-12">Note</div>
        <div class="typography-8 mr-12 mb-24">{{profiles[0].note || multiprofile?.note}}</div>
      </template>
      <template v-if="$route.name !== 'Report'">
        <div class="color-miniheader typography-theme mb-12">Note</div>
        <div class="d-flex align-center mb-24">
          <div class="typography-8 mr-12" v-if="!inputNote">{{note || 'This person is our main target...'}}</div>
          <textarea
            cols="40"
            rows="7"
            class="report-textarea mr-16"
            v-model="note"
            @keydown.enter="inputNote = false"
            id="note"
            v-else
          ></textarea>
          <button @click="inputNote = true" v-if="!inputNote" id="edit-note">
            <Icon name="edit" class="icon-hover" />
          </button>
          <template v-else>
            <button @click="inputNote = false" id="save-note"><Icon name="ok" /></button>
          </template>
        </div>
      </template>
      <div class="color-miniheader typography-theme mb-12">Sources</div>
      <ul class="typography-8">
        <li class="d-flex align-center mb-8" v-for="profile in profiles" :key="profile.oid">
          <span class="icon-wrap">
            <Icon :name="socialIconFromProfile(profile)" :data-svg-replace="socialIconFromProfile(profile)" class="mr-12" />
          </span>
          <span>{{ name(profile) }} &nbsp;</span>
          <span class="color-theme" v-if="profileId(profile)" v-html="profileId(profile)"></span>
        </li>
      </ul>
    </div>
  </div>
  <div class="d-flex justify-between">
    <h4 class="typography-3 color-miniheader mb-24">Information</h4>
    <button class="fs-14 fw-600 link csv-btn" @click="loadCSV"><Icon name="download" class="mr-12" />Download CSV</button>
  </div>
  <ul class="profile-info-list" :class="allUserDetails && Object.keys(allUserDetails).length ? 'mb-24' : 'bottom-margin'">
    <li v-for="(val, key) in mainInfo" :key="key" class="d-flex">
      <p class="color-1 typography-7 subhead-width">{{key}}</p>
      <div class="d-flex justify-between align-start w-100">
        <span class="lh-24" v-html="val.value" v-linkified></span>
        <div class="d-flex align-center mt-6">
          <span class="icon-wrap" v-for="social in val.socialNetwork" :key="social">
            <Icon :name="socialNetworkIcon(social)" :data-svg-replace="socialNetworkIcon(social)" color="#A0B2BE" />
          </span>
        </div>
      </div>
    </li>
  </ul>
  <div class="bottom-padding">
    <div v-for="(type, name) in allUserDetails" :key="type">
      <p class="typography-theme mb-12">{{name}}</p>
      <ul class="profile-info-list mb-48">
        <li v-for="(row, propertyName) in type" :key="row + idx" class="d-flex justify-between">
          <div class="subhead-width" v-if="name === 'Social events'"><img src="@/assets/icons/social-event.png" alt="" class="big-icon"></div>
          <div v-for="(val, key) in row" :key="key"  :class="{'d-flex': setClass1(name), 'w-100': key !== 'socialNetwork'}">
            <template v-if="key !== 'socialNetwork'">
              <p class="color-1 typography-7" :class="setClass2(name)" v-html="propertyName"></p>
              <div v-if="typeof(val) === 'object' && val.length" class="d-flex flex-column">
                <span class="lh-24" v-for="v in val" :key="v" v-html="v"></span>
              </div>
              <span class="lh-24" v-else v-html="val"></span>
            </template>
            <span class="icon-wrap mt-6" v-for="network in val" :key="network" v-else>
              <Icon :name="socialNetworkIcon(network)" :data-svg-replace="socialNetworkIcon(network)" color="#A0B2BE" />
            </span>
          </div>
        </li>
      </ul>
    </div>
    <div class="mt-48" v-if="Object.keys(sortedConnectionsByScore).length">
      <h4 class="typography-3 color-miniheader mb-16">Connections</h4>
      <div class="score-desc p-0 mb-32">
        <p class="fw-600">Score</p>
        <p><span class="fw-600">Friend (+50 points) –</span> the profile is in the subjects’s friend list</p>
        <p><span class="fw-600">Tagged with (+30 points) –</span> the person was tagged in a photo with the subject</p>
        <p><span class="fw-600">Liked post/photo (+3 points) –</span> the person liked the user's post or photo</p>
        <p><span class="fw-600">Commented post/photo (+3 points) –</span> the person commented on the  subject's post or photo</p>
      </div>
      <ul class="fast-report-connections typography-8" v-for="(connections, title) in sortedConnectionsByScore" :key="title">
        <span v-if="connections.length" class="d-block typography-theme color-miniheader mb-12 mt-12">{{title}}</span>
        <ConnectionMini :connections="connections" />
      </ul>
    </div>
  </div>
</template>

<script>
import Icon from '../app/Icon';
import ConnectionMini from '@/components/ConnectionMiniItem';
import { getEntityAvatar, getEntityName, clearingString } from '../../utils/helpers';
import { mapGetters } from 'vuex';
import { ExportToCsv } from 'export-to-csv';
import profilePreviewMixin from '@/mixins/profilePreviewMixin';

export default {
  name: 'ReportProfilePreview',
  components: {
    Icon,
    ConnectionMini
  },
  props: ['profiles', 'multiprofile'],
  mixins: [profilePreviewMixin],
  data () {
    return {
      inputNote: false,
      note: '',
      loadImage: false
    };
  },
  computed: {
    ...mapGetters(['connections']),
    mainInfo () {
      return this.getInfoOfProfiles(this.profiles);
    },
    mainName () {
      function sortNames (maininfo, socialNetwork) {
        const socialNetworks = maininfo.name.socialNetwork;
        for (let i = 0; i < socialNetworks.length; i++) {
          if (socialNetworks[i] === socialNetwork) {
            return maininfo.name.value[i];
          }
        }
        return '';
      }
      if (this.mainInfo && this.mainInfo.name) {
        let sortedName;
        sortedName = sortNames(this.mainInfo, 'maltego.facebook.profile');
        if (sortedName) {
          return sortedName;
        }
        sortedName = sortNames(this.mainInfo, 'maltego.linkedin.profile');
        if (sortedName) {
          return sortedName;
        }
        sortedName = sortNames(this.mainInfo, 'maltego.affiliation.Twitter');
        if (sortedName) {
          return sortedName;
        }
        sortedName = sortNames(this.mainInfo, 'maltego.instagram.profile');
        if (sortedName) {
          return sortedName;
        }
      }
      return '';
    },
    userDetailsObjForSorting () {
      return this.getUserDetails(this.profiles);
    },
    allUserDetails () {
      return this.getSortingUserDetails(this.userDetailsObjForSorting);
    },
    sortedConnectionsByScore () {
      const obj = {};
      obj['250+'] = [];
      obj['150-249'] = [];
      obj['50-149'] = [];
      obj['1-49'] = [];
      if (this.multiprofile) {
        return this.connectionsToMultiprofile(obj);
      } else {
        return this.connectionsToProfile(obj);
      }
    }
  },
  methods: {
    sourceAvatar (profile) {
      return getEntityAvatar(profile);
    },
    avatarLoadHandler () {
      this.loadImage = true;
    },
    avatarErrorHandler () {
      this.loadImage = false;
    },
    socialNetworkIcon (socialNetwork) {
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (socialNetwork.includes(socialNet)) {
          return 'person-card-' + socialNet.toLowerCase();
        }
      }
    },
    name (profile) {
      return getEntityName(profile);
    },
    socialIconFromProfile (profile) {
      try {
        for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
          if (profile.typeid.includes(socialNet)) {
            return 'person-card-' + socialNet.toLowerCase();
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    profileId (profile) {
      if (profile.fields.id) {
        return `<a href="${profile.fields.surl?.value || profile.fields.url?.value}" target="_blank">(id: ${profile.fields.id.value})</a>`;
      } else if (profile.fields['twitter.id']) {
        return `<a href="${profile.fields['affiliation.profile-url'].value}" target="_blank">(id: ${profile.fields['twitter.id'].value})</a>`;
      } else if (profile.fields.number_id) {
        return `<a href="${profile.fields.surl.value}" target="_blank">(id: ${profile.fields.number_id.value})</a>`;
      } else if (profile.fields.alias && profile.fields.surl) {
        return `<a href="${profile.fields.surl.value}" target="_blank">(alias: ${profile.fields.alias.value})</a>`;
      } else return '';
    },
    setClass1 (field) {
      return field !== 'Relatives' && field !== 'Social events' && field !== 'Education' && field !== 'Experience' && field !== 'Volunteer expirience' && field !== 'Projects' ? 'd-flex align-start' : '';
    },
    setClass2 (field) {
      return field !== 'Relatives' && field !== 'Social events' && field !== 'Education' && field !== 'Experience' && field !== 'Volunteer expirience' ? 'subhead-width' : '';
    },
    connectionsToProfile (obj) {
      const profile = this.profiles[0];
      if (!profile.scoreToShow) return {};
      const connections = this.connections.filter(conn => {
        return conn.parent.oid === profile.oid;
      });
      connections.forEach(connection => {
        if (profile.scoreToShow['s-250+'] && connection.fields.score.value >= 250) {
          obj['250+'].push(connection);
        } else if (profile.scoreToShow['s-150+'] && connection.fields.score.value >= 150 && connection.fields.score.value <= 249) {
          obj['150-249'].push(connection);
        } else if (profile.scoreToShow['s-50+'] && connection.fields.score.value >= 50 && connection.fields.score.value <= 149) {
          obj['50-149'].push(connection);
        } else if (profile.scoreToShow['s-1+'] && connection.fields.score.value <= 49) {
          obj['1-49'].push(connection);
        }
      });
      const key = Object.keys(obj).find(key => {
        return obj[key].length;
      });
      if (key) {
        return obj;
      } else {
        return {};
      }
    },
    connectionsToMultiprofile (obj) {
      if (!this.multiprofile.scoreToShow) return {};
      const connections = [];
      this.profiles.forEach(profile => {
        this.connections.filter(conn => {
          if (conn.parent.oid === profile.oid) {
            connections.push(conn);
          }
        });
      });
      connections.forEach(connection => {
        if (this.multiprofile.scoreToShow['s-250+'] && connection.fields.score.value >= 250) {
          obj['250+'].push(connection);
        } else if (this.multiprofile.scoreToShow['s-150+'] && connection.fields.score.value >= 150 && connection.fields.score.value <= 249) {
          obj['150-249'].push(connection);
        } else if (this.multiprofile.scoreToShow['s-50+'] && connection.fields.score.value >= 50 && connection.fields.score.value <= 149) {
          obj['50-149'].push(connection);
        } else if (this.multiprofile.scoreToShow['s-1+'] && connection.fields.score.value <= 49) {
          obj['1-49'].push(connection);
        }
      });
      const key = Object.keys(obj).find(key => {
        return obj[key].length;
      });
      if (key) {
        return obj;
      } else {
        return {};
      }
    },
    loadCSV () {
      const data = {};
      for (const key of Object.keys(this.mainInfo)) {
        if (Array.isArray(this.mainInfo[key].value)) {
          data[key] = this.mainInfo[key].value.join(', ');
        } else {
          data[key] = this.mainInfo[key].value;
        }
      }
      for (const key of Object.keys(this.allUserDetails)) {
        const headerBlock = this.allUserDetails[key];
        for (const keyHeader of Object.keys(headerBlock)) {
          let keyArr = keyHeader.split('<br>');
          if (keyArr.length > 1) {
            keyArr = keyArr[keyArr.length - 1];
          } else {
            keyArr = keyHeader;
          }
          const lowerCaseKey = `"${keyArr.toLowerCase()} (${key})"`;
          let str;
          if (Array.isArray(headerBlock[keyHeader].value)) {
            str = headerBlock[keyHeader].value.join(', ');
          } else {
            str = headerBlock[keyHeader].value;
          }
          // Deleting tags
          data[lowerCaseKey] = clearingString(str);
        }
      }
      const socialNetworks = this.mainInfo.name.socialNetwork.reduce((str, social) => {
        if (social.indexOf('facebook') !== -1) {
          return str + 'facebook ';
        } else if (social.indexOf('twitter') !== -1) {
          return str + 'twitter ';
        } else if (social.indexOf('instagram') !== -1) {
          return str + 'instagram ';
        } else {
          return str + 'linkedin ';
        }
      }, '');
      data.socialNetwork = socialNetworks;

      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: `${data.name} information`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: `${data.name}`
      };
      console.log([data]);
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv([data]);
    }
  }
};
</script>

<style lang="scss" scoped>
.report-textarea {
  background: #eff4f7;
  padding: 12px;
  width: 100%;
  border-radius: 6px;
}
</style>
