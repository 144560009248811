<template>
  <div class="popup">
    <div class="popup-left">
      <div class="d-flex align-center justify-between mb-12">
        <span class="color-link typography-3">Export settings</span>
      </div>
      <div class="typography-7 terms-color let-space-08 mb-16">View connected events on timeline or save whole connection to add to a report later.</div>
      <button id="report-back" class="typography-7 let-space-08 theme-hover" @click="closePreview">
        <div class="tag-circle mr-12">
          <Icon name="arrow-left-blue" />
        </div>
        Back
      </button>
    </div>
    <Feature visible="VUE_APP_NSA_1658">
      <div class="popup-right">
        <div class="select mb-12">
          <Dropdown v-model="typeToPrint" :options="types" optionLabel="name" />
        </div>
        <div class="typography-7 let-space-08 mb-16">Choose format to share your case results with other people.</div>
        <button id="report-download" class="typography-7 let-space-08" @click="exportTo" :disabled="exporting">
          <Icon name="download" class="mr-12" />
          Download
          <div class="right-icon right-icon--0 d-flex" v-if="exporting">
            <span class="load-circle load-circle--1"></span>
            <span class="load-circle load-circle--2"></span>
            <span class="load-circle load-circle--3"></span>
          </div>
        </button>
      </div>
    </Feature>
    <Feature visible="!VUE_APP_NSA_1658">
      <div class="popup-right">
        <div class="typography-3 mb-12">Export to PDF</div>
        <div class="typography-7 let-space-08 mb-16">Create a PDF document to send your investigation results to other people.</div>
        <button id="report-download" class="typography-7 let-space-08" @click="exportTo" :disabled="exporting">
          <Icon name="download" class="mr-12" />
          Download
          <div class="right-icon right-icon--0 d-flex" v-if="exporting">
            <span class="load-circle load-circle--1"></span>
            <span class="load-circle load-circle--2"></span>
            <span class="load-circle load-circle--3"></span>
          </div>
        </button>
      </div>
    </Feature>
  </div>
</template>

<script>
import Icon from '../app/Icon';
import html2pdf from 'html2pdf.js';
import Feature from '@/components/app/Feature';
import Dropdown from 'primevue/dropdown';

export default {
  name: 'ReportPopup',
  components: {
    Icon,
    Feature,
    Dropdown
  },
  props: ['xls'],
  data () {
    return {
      exporting: false,
      typeToPrint: { name: 'Export to .PDF', code: 'pdf' },
      types: [
        { name: 'Export to .PDF', code: 'pdf' },
        { name: 'Export to .XLSX', code: 'xls' }
        // { name: 'Export to .HTML', code: 'html' }
      ]
    };
  },
  emits: ['closePreview', 'createXLS', 'createHtml'],
  methods: {
    closePreview () {
      this.$emit('closePreview');
      this.$store.commit('hideAvatars', false);
    },
    exportTo () {
      if (this.typeToPrint.code === 'xls') {
        this.$emit('createXLS');
      } else if (this.typeToPrint.code === 'html') {
        this.$emit('createHtml');
      } else {
        this.exporting = true;
        this.$store.commit('hideAvatars', true);
        document.querySelectorAll('.icon-hover').forEach(elem => {
          elem.style.display = 'none';
        });
        document.querySelectorAll('.tagged-profile-card').forEach(elem => {
          elem.classList.add('card-border');
        });
        var element = document.getElementById('htmltopdf');
        var opt = {
          margin: [8, 10],
          pagebreak: { mode: ['legacy'], avoid: ['img', 'li'] },
          image: { type: 'jpeg', quality: 1 },
          enableLinks: true,
          html2canvas: { useCORS: true }
        };
        html2pdf().set(opt).from(element).save().then(() => {
          document.querySelectorAll('.icon-hover').forEach(elem => {
            elem.style.display = 'block';
          });
          document.querySelectorAll('.tagged-profile-card').forEach(elem => {
            elem.classList.remove('card-border');
          });
          this.exporting = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.popup {
  z-index: 2005;
}
.p-dropdown {
  width: 13rem;
}
.country-item {
  img {
    width: 17px;
    margin-right: 0.5rem;
  }
}
</style>
